/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { media } from '@atlaskit/primitives/responsive';
import Stack from '@atlaskit/primitives/stack';
import { token } from '@atlaskit/tokens';

import HorizontalScrollContainer from '../../horizontal-scroll-container';
import CollapsingTopMargin from '../../utils/collapsing-top-margin';
import { type ColorTable, type GroupedColorTable } from '../types';

const tableStyles = css({
	[media.below.sm]: {
		display: 'block',
	},
});

const tableHeadStyles = css({
	[media.below.sm]: {
		display: 'none',
	},
});

const tableBodyStyles = css({
	[media.below.sm]: {
		display: 'block',
	},
});

const rowStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::not(::last-of-type)': {
		borderBlockEnd: `1px solid ${token('color.border')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::last-of-type': {
		borderBlockEnd: 0,
	},

	[media.below.sm]: {
		display: 'grid',
		rowGap: token('space.100', '8px'),
		paddingBlockEnd: token('space.150', '12px'),
		paddingBlockStart: token('space.150', '12px'),
	},
});

const cellStyles = css({
	[media.below.sm]: {
		display: 'block',
		padding: token('space.0', '0px'),
	},

	[media.above.sm]: {
		paddingBlockEnd: token('space.200', '16px'),
		paddingBlockStart: token('space.200', '16px'),
	},
});

const headerRowStyles = css({
	[media.below.sm]: {
		display: 'none',
	},
});

const tagContainerStyles = css({
	display: 'flex',
	width: 'fit-content',
	height: 'fit-content',
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'row',
	borderRadius: token('border.radius.circle', '100px'),
});

const colorDotStyles = css({
	width: '0.75rem',
	height: '0.75rem',
	borderRadius: token('border.radius.circle', '50%'),

	[media.above.sm]: {
		width: '1rem',
		height: '1rem',
	},
});

const tokenNameStyles = css({
	font: token('font.body.UNSAFE_small'),
	fontFamily: token('font.family.code'),
	marginInlineEnd: token('space.100', '8px'),
	[media.above.sm]: {
		font: token('font.body'),
		fontFamily: token('font.family.code'),
	},
});

const renderTag = ({ name, background, input, inverse, icon, token: iconToken }: ColorTable) => (
	<div
		css={tagContainerStyles}
		style={{
			backgroundColor: background || token('color.background.neutral'),
			border: `1px solid ${input ? token('color.border.input') : 'none'}`,
		}}
	>
		<code
			css={tokenNameStyles}
			style={{
				color: inverse ? token('color.text.inverse') : token('color.text'),
			}}
		>
			{name}
		</code>
		{icon ? (
			<div css={colorDotStyles}>{icon}</div>
		) : (
			<div
				css={colorDotStyles}
				style={{
					backgroundColor: iconToken,
				}}
			/>
		)}
	</div>
);

/**
 * __Base Table__
 *
 * A base color table that formed by color names the left, and descriptions on the right.
 *
 */
const BaseTable = ({
	tableData,
	categoryName,
}: {
	tableData: ColorTable[] | GroupedColorTable;
	categoryName: string;
}) => {
	return (
		<CollapsingTopMargin>
			<HorizontalScrollContainer>
				<table css={tableStyles}>
					<thead css={tableHeadStyles}>
						<tr css={[rowStyles, headerRowStyles]}>
							<th css={cellStyles}>{categoryName}</th>
							<th css={cellStyles}>Description</th>
						</tr>
					</thead>
					<tbody css={tableBodyStyles}>
						{Array.isArray(tableData)
							? tableData.map((item: ColorTable) => (
									<tr key={item.name} css={rowStyles}>
										<td css={cellStyles}>{renderTag(item)}</td>
										{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
										<td css={cellStyles} style={{ whiteSpace: 'pre-line' }}>
											{item.description}
										</td>
									</tr>
								))
							: Object.values(tableData).map((groupedItems: ColorTable[]) => (
									<tr key={groupedItems[0].name} css={rowStyles}>
										<td css={cellStyles}>
											<Stack space="space.100">
												{groupedItems.map((item: ColorTable) => renderTag(item))}
											</Stack>
										</td>
										{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
										<td css={cellStyles} style={{ whiteSpace: 'pre-line' }}>
											{groupedItems[0].description}
										</td>
									</tr>
								))}
					</tbody>
				</table>
			</HorizontalScrollContainer>
		</CollapsingTopMargin>
	);
};

export default BaseTable;
